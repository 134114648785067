var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpActionPage, ImpButton, ImpButtonDock, ImpContainer, ImpFlex, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { FORM_BUTTON_WIDTH, brooksBrothersMicrosite } from '../../assets/consts/const';
import { getDaysToConversion } from '../../utils/conversionUtils';
export function ConversionCountdownInfoPage(_a) {
    var onClose = _a.onClose;
    var language = useAppSelector(selectLanguage);
    var handleViewMoreInfoClick = function () {
        window.open(brooksBrothersMicrosite, '_blank');
    };
    return (_jsxs(ImpActionPage, __assign({ footer: _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH, mobileWidth: "100%" }, { children: _jsxs(ImpButtonDock, __assign({ alwaysColumn: true }, { children: [_jsx(ImpButton, __assign({ size: "fill", buttonStyle: 'branded', onClick: onClose }, { children: language.okay })), _jsx(ImpButton, __assign({ size: "fill", variant: "secondary", buttonStyle: 'branded', onClick: handleViewMoreInfoClick, linkOut: true }, { children: language.viewMoreInfo }))] })) })), widthPx: 601, offset: "62px" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsxs(ImpFlex, __assign({ direction: "column", align: "center", gap: "s", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "headline3" }, { children: language.youCanUseYourNewCardIn + getDaysToConversion(language) })), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant, handleSuperscript: true }, { children: language.yourNewBBCardsWillBecomeEligibleOn1 })), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant, handleSuperscript: true }, { children: language.yourNewBBCardsWillBecomeEligibleOn2 }))] })), _jsx(ImpSpacer, { height: "2xl" })] })));
}
