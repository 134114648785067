import { BASE_URL, ENV, setBaseURL, setEnv } from '../app/apiScreenSlice';
import { store } from '../app/store';
export function isLocal(domain) {
    // Checks for common private IP address patterns or 'localhost'
    var localIpRegex = /^(192\.168|10\.|172\.(1[6-9]|2[0-9]|3[0-1]))\.\d+\.\d+$/;
    return domain === ENV.LOCAL || localIpRegex.test(domain);
}
export function isDev() {
    return isLocal(window.location.hostname.toLowerCase());
}
export var fetchEnvFromDomain = function (domain) {
    var baseURL = "";
    var env = '';
    var domainParse = domain.split('.');
    // localhost
    if (isLocal(domain)) {
        baseURL = BASE_URL.STG;
        env = ENV.STG;
    }
    // stg envs
    else if (domainParse.includes(ENV.STG)) {
        baseURL = BASE_URL.STG;
        env = ENV.STG;
    }
    // preprod envs
    else if (domainParse.includes(ENV.PREPRD)) {
        baseURL = BASE_URL.PREPRD;
        env = ENV.PREPRD;
    }
    // prd envs
    else if (env === '') {
        baseURL = BASE_URL.PROD;
        env = ENV.PROD;
    }
    store.dispatch(setBaseURL(baseURL));
    store.dispatch(setEnv(env));
};
export function isStaging() {
    return window.location.hostname.toLowerCase().split('.').includes(ENV.STG);
}
export var isInternalProd = function () {
    return window.location.hostname.toLowerCase().split('.').includes(ENV.INTERNAL_PROD);
};
