import { jsx as _jsx } from "react/jsx-runtime";
import { FeatureAnnouncementPage } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { ANALYTICS } from '../../firebase/firebase';
import { isPostConversion } from '../../utils/conversionUtils';
export function ConvertedHomeWelcomePage(_a) {
    var onClose = _a.onClose;
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    useEffect(function () {
        ANALYTICS.logEvent(isPostConversion() ? EVENT.POSTCONVERSION_WELCOME_VIEWED : EVENT.PRECONVERSION_WELCOME_VIEWED, {
            product_uuid: merchantData.productUUID,
        });
    }, []);
    return (_jsx(FeatureAnnouncementPage, { badgeText: language.welcomeToImprint.toUpperCase(), title: isPostConversion() ? language.convertedBBCardReady : language.bestWayToEarnRewards, subtitle: language.welcomeUseImprintAccount, buttonText: isPostConversion() ? language.continueBtn : language.getStartedBtn, onCloseClick: onClose, onPrimaryClick: function () {
            onClose();
            ANALYTICS.logEvent(isPostConversion()
                ? EVENT.POSTCONVERSION_WELCOME_CLICKED
                : EVENT.PRECONVERSION_WELCOME_CLICKED, {
                product_uuid: merchantData.productUUID,
            });
        } }));
}
