var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icons, ImpFlex, ImpFlexItem, ImpFlyout, ImpIcon, ImpPad, ImpText, borderRadius, colors, useModal, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { getDaysToConversion } from '../../utils/conversionUtils';
import { ConversionCountdownInfoPage } from './ConversionCountdownInfoPage';
export function ConversionCountdownBanner() {
    var language = useAppSelector(selectLanguage);
    var _a = useModal(false), isDetailsOpen = _a[0], isDetailsClosing = _a[1], openDetailsModal = _a[2], closeDetailsModal = _a[3];
    return (_jsxs(_Fragment, { children: [_jsx(ImpFlyout, __assign({ isOpen: isDetailsOpen, isClosing: isDetailsClosing, close: closeDetailsModal, padDefault: false, hideBack: true }, { children: _jsx(ConversionCountdownInfoPage, { onClose: closeDetailsModal }) })), _jsx("div", __assign({ style: {
                    width: 'calc(100% - 32px)',
                    zIndex: 2,
                    borderRadius: borderRadius.s,
                    backgroundColor: colors.surface.surfaceHigh,
                    boxShadow: '0px 4px 16px -4px rgba(0, 0, 0, 0.25)',
                    position: 'absolute',
                    top: '16px',
                    cursor: 'pointer',
                }, onClick: openDetailsModal }, { children: _jsx(ImpPad, __assign({ paddingTop: "m", paddingBottom: "m", paddingLeft: "l", paddingRight: "l" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", gap: "l" }, { children: [_jsx(ImpIcon, { iconSrc: Icons.warningCircle, stroke: colors.content.onSurfaceWarning, size: "32px" }), _jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsxs(ImpFlex, __assign({ direction: "column" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", color: colors.content.onSurface }, { children: getDaysToConversion(language) })), _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onSurfaceVariant }, { children: language.untilUseNewCard }))] })) })), _jsx(ImpIcon, { iconSrc: Icons.chevronRight })] })) })) }))] }));
}
